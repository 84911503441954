export const ROUTE = {
  home: '/',
  about: '/about',
  team: '/team',
  coaching: '/coaching',
  books: '/books',
  articles: '/articles',
  socialMedia: '/social-media',
  veganAthletes: '/athletes'
}

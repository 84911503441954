import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { ROUTE } from "../../parameters/general"
import { breakpoints } from "../../parameters/theme"

const Container = styled.nav`
  left: 1rem;
  position: absolute;
  top: 1em;

  li {
    padding: 0 0.5em;
  }

  a {
    color: #ffffff;
    font-size: 0.8em;
    font-weight: 800;
    padding: 1em;
    text-decoration: none;
    text-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
    text-transform: uppercase;

    &:active,
    &:focus,
    &:hover {
      text-shadow: 0 0 0.8em #ffffff;
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    ul li {
      &:first-of-type a::before {
        color: #ffffff;
        content: "\\2302 ";
        font-size: 1.2em;
        margin-right: 0.5em;
        text-decoration: none;
        text-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
      }

      &:not(:first-of-type) {
        display: none;
      }
    }
  }
`

const HeaderNav = () => (
  <Container className="section-menu">
    <ul>
      <li>
        <Link to={ROUTE.home}>HOME</Link>
      </li>
      <li>
        <Link to={ROUTE.articles}>ARTICLES</Link>
      </li>
      <li>
        <Link to={ROUTE.coaching}>COACHING</Link>
      </li>
      <li>
        <Link to={ROUTE.books}>BOOKS</Link>
      </li>
    </ul>
  </Container>
)

export default HeaderNav

import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import { subscribe } from "../lib/mailer-lite"
import { ROUTE } from "../parameters/general"

const ContainerAside = styled.aside`
  background: #eeeeee;
  margin-top: 3em;
  padding: 2em 1em;

  & > * {
    margin-left: auto;
    margin-right: auto;
    max-width: 768px;
  }

  .title {
    font-size: 2em;
  }

  .description {
    margin-top: 1em;
  }

  .cta {
    margin-top: 1em;
    text-align: right;

    .subscribe-button {
      border-bottom: 1px solid #333333;
      color: #333333;
      text-decoration: none;

      &:active,
      &:focus,
      &:hover {
        border-bottom: 1px solid #339933;
        color: #339933;
      }
    }
  }
`

const ContainerFooter = styled.footer`
  min-height: 60%;

  ul {
    margin-left: auto;
    margin-right: auto;
    max-width: 768px;

    li {
      display: inline-block;
      margin-top: 1em;
      padding-left: 0.5em;
      padding-right: 0.5em;
    }

    a {
      color: #777777;
      text-decoration: none;

      &:active,
      &:focus,
      &:hover {
        color: #333333;
      }
    }
  }

  .logo {
    clear: both;
    display: block !important;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    width: 768px !important;
  }
`

const Footer = ({ logoFluid }) => (
  <>
    <ContainerAside>
      <h2 className="title">Do you also want to be a strong vegan?</h2>
      <p className="description">
        Join the rapidly growing number of athlegans! Subscribe for free and
        receive all the information you need to improve your training, clean up
        your diet, and become a stronger vegan.
      </p>
      <p className="cta">
        <a
          className="subscribe-button"
          role="button"
          onClick={e => {
            subscribe(e)
          }}
          onKeyDown={e => {
            subscribe(e)
          }}
          href="/subscribe"
        >
          Subscribe
        </a>
      </p>
    </ContainerAside>
    <ContainerFooter>
      <ul>
        <li>
          <Link to={ROUTE.about}>About Athlegan</Link>
        </li>
        <li>
          <Link to={ROUTE.team}>The team</Link>
        </li>
        <li>
          <Link to={ROUTE.socialMedia}>Social media</Link>
        </li>
        <li>
          <Link to={ROUTE.veganAthletes}>Vegan athletes</Link>
        </li>
      </ul>
      <Img className="logo" fluid={logoFluid} />
      <script src="https://my.hellobar.com/8c7f5faaf88f393fa02d2c6d03ae8459d3da7ab8.js" type="text/javascript" charset="utf-8" async="async"> </script>
    </ContainerFooter>
  </>
)

export default Footer

export const subscribe = e => {
  if (e.keyCode && +e.keyCode !== 13) {
    // User presses a button
    return
  } else if (e) {
    // User uses mouse
    e.preventDefault()
  }

  window.ml_webform_1590320 && window.ml_webform_1590320("show")
}
